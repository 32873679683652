import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import SEO from '../components/SEO';
import Layout from '../components/Layout';
import Hero from '../components/Hero';
import BespokeBox from '../components/BespokeBox';

import '../css/pages/_services-page.scss';

export default function services({ data }) {
  const pageData = data.sanityServicesPage;
  const allServices = data.allSanityService.nodes;

  return (
    <Layout>
      <SEO title="Services" />

      <div className="services-page">
        {/* Hero section */}
        <Hero h1={pageData.title} p={pageData.desc} />

        {/* Services listing */}
        <section className="all-services">
          <div className="wrapper">
            {allServices.map((service, i) => (
              <div className="service" key={i}>
                <div className="thumb">
                  <GatsbyImage
                    image={service.mainImage.asset.gatsbyImageData}
                    alt={service.mainImage.alt}
                    className="img"
                  />
                </div>
                <h4>{service.title}</h4>
                <p>{service.desc}</p>
              </div>
            ))}
          </div>
        </section>

        {/* Bespoke Service */}
        <BespokeBox />
      </div>
    </Layout>
  );
}

export const query = graphql`
  {
    sanityServicesPage {
      title
      desc
    }
    allSanityService(sort: { fields: order }) {
      nodes {
        title
        desc
        mainImage {
          alt
          asset {
            gatsbyImageData
          }
        }
      }
    }
  }
`;
